import React, { useEffect, useCallback, useRef, useState, useMemo, FunctionComponent } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import Hexes from '../components/Hexes.js';
import Carousel from '../components/Carousel';
import FooterForm from '../components/FooterForm';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import AnimHexgroup from '../components/AnimHexgroup.js';
import AnimDesire from '../components/AnimDesire.js';
// import anime from 'animejs';
// import CovidBanner from '../components/CovidBanner';
import MouseScrollIndicator from '../components/MouseScrollIndicator';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import BigHex from '../components/BigHex';
// import Lightning from '../components/Lightning';
import { useSpring, SpringValue, to, config, animated as a } from 'react-spring';
import HomeMobile from '../components/HomeMobile';
import StickySlider from '../components/StickySlider';
import Hls from 'hls.js';
import arrow from '../img/home/arrow.svg';
import { getStrapiMedia, NEXTJS_BASE_URL } from '../utils/lib';

import useScrollAnimation from '../utils/use-scrollanimation2';
import { last } from 'lodash';
// import { ReactLenis, useLenis } from '@studio-freight/react-lenis';

// import { getClientOffset } from '../utils/get-client-offset';

// import { LenisParallax } from '../components/parallax.js';

// const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React);

const StyledPanel1 = styled.div`
  h1,
  h2 {
    opacity: 0;
    transition: opacity 1.2s ease-out;
    position: fixed;
    text-align: center;
    width: 100%;
    max-width: 500px;
    left: calc(50% - 250px);
    z-index: 6;
    text-transform: uppercase;
    font-family: 'Roboto';
  }
  h1 {
    top: 18vh;
    letter-spacing: 25px;
    font-weight: 500;
    font-size: 28px;
    margin-left: 0.3em;
  }
  h2 {
    top: 80vh;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 22px;
  }
  h1.reveal,
  h2.reveal {
    opacity: 1;
  }
`;

const StyledPanel = styled.div`
  height: 100vh;
  top: 0px;
  width: 100%;
  z-index: 1;
  /* background-color: #01426a;  */
  /* .wrapper1 {
    position: sticky;
    top: 550px;
    height: 2532px;
  }

  .tester {
    position: sticky;
    top: 400px;
    font-size: 70px;
    text-align: center;
  } */
  .panel-background {
    height: 100%;
    object-position: center;
    object-fit: cover;
    z-index: -1;
    /* &:after {
      content: '';
      background-color: black;
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: -2;
    } */
  }
  .panel-background2 {
    height: 100%;
    object-position: center;
    object-fit: cover;
    z-index: -2;
  }
  .panel-creativity {
    max-width: 950px !important;
    margin: 0 auto;
    box-sizing: border-box;
    h2#creativity {
      font-family: 'Roboto';
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: 4.2px;
      text-align: center;
      color: #fff;
      -webkit-text-fill-color: #000;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #fff;
      span {
        color: #fff;
        -webkit-text-fill-color: #fff;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
      }
      .animate-desire {
        /* opacity: 0; */
      }
    }
    .copy-wrapper {
      padding-bottom: 0 !important;
      padding-top: 0px;
    }
  }

  .content {
    max-width: none;
    bottom: 5vh;
    height: 100%;
    text-align: left;
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; */
    box-sizing: border-box;
    padding: 0 55px;
    padding-top: 24vh;
    .image-wrapper {
      text-align: center;
    }
    .copy-wrapper {
      padding-bottom: 188px;
      .wrapper {
        max-width: 100%;
        width: 100%;
        padding-left: 50px;
      }
    }
    .half {
      max-width: 48%;
      width: 100%;
      position: relative;
    }
    p {
      font-size: 30px;
    }
    .monitor {
      max-width: 540px;
      width: 100%;
      margin: 0 auto;
    }
    .facebook-scroll-container,
    .facebook-scroll-container2 {
      position: absolute;
      overflow: hidden;
      top: 5%;
      max-width: 496px;
      width: 100%;
      height: 280px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: -1;
      > div {
        position: absolute;
        max-width: 100%;
        width: 100%;
      }
      .image-1 {
        top: 0px;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      .half {
        max-width: 100%;
      }
      .image-wrapper {
        margin-bottom: 50px;
      }
      .copy-wrapper {
        padding-bottom: 0;
        .wrapper {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-height: 960px) {
    .content {
      bottom: 0px;
    }
  }
  h2 {
    text-transform: uppercase;
    letter-spacing: 0.015em;
    margin: 0 0 35px;
    font-size: 4rem;
    line-height: 4rem;
    color: white;
    font-weight: 400;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
  a.cta {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.05em;
    padding: 14px 42px;
    border: 2px solid #41b6e6;
    margin-bottom: 0;
  }
  h3 {
    color: #009bce;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: -50px;
    margin-bottom: 50px;
    width: 555px;
    text-align: center;
  }

  @keyframes mask-play {
    0% {
      mask-position: 0% 0;
    }
    100% {
      mask-position: 100% 0;
    }
  }
`;

const RedesignPanel = styled.div`
  #panel3-4 {
    padding-top: 155px;
    padding-bottom: 120px;
    .fadeDownInit {
      opacity: 0;
      transform: translateY(-100px);
      transition-property: opacity, transform;
      transition-timing-function: ease;
      transition-duration: 0.8s;
    }
    .fadeInDown {
      opacity: 1;
      transform: translateY(0);
    }
    .content {
      max-width: 1440px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      grid-column-gap: 114px;
      a.cta {
        border: 2px solid #41b6e6;
        color: #000;
        letter-spacing: 0.9px;
        padding: 15px 42px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        outline: 1px solid white;
        &:hover {
          outline: 1px solid #41b6e6;
        }
      }
      .half {
        max-width: 50%;
        width: 100%;
        h2 {
          color: #000;
          font-family: Oswald;
          font-size: 48px;
          font-style: normal;
          font-weight: 300;
          line-height: 52px;
          margin: 0 0 40px 0;
          text-transform: uppercase;
        }
        p {
          color: #000;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: 0.32px;
          &:last-of-type {
            margin-bottom: 40px;
          }
        }
        .image-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
          grid-column-gap: 44px;
          grid-row-gap: 50px;
          align-items: center;
          justify-items: center;
          /* display: flex;
          flex-direction: row;
          flex-wrap: wrap; */
          .gatsby-image-wrapper {
            max-width: 33.33%;
            width: 100%;
            img {
              width: 100%;
              height: auto !important;
              max-height: 100% !important;
            }
          }
        }
        &:nth-of-type(1) {
          max-width: 530px;
          width: 100%;
        }
        &:nth-of-type(2) {
          max-width: 484px;
          width: 100%;
        }
      }
      @media only screen and (max-width: 1150px) {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        .half {
          &:nth-of-type(1) {
            max-width: 60%;
            width: 100%;
          }
          &:nth-of-type(2) {
            max-width: 40%;
            width: 100%;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        .half {
          &:nth-of-type(1) {
            max-width: 100%;
            width: 100%;
          }
          &:nth-of-type(2) {
            max-width: 100%;
            width: 100%;
            margin-bottom: 25px;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      padding-top: 50px;
    }
  }

  #panel4 {
    background-color: #fff;
    padding-bottom: 58px;
    border-bottom: 2px solid #d6d6d6;
    .content {
      box-sizing: content-box;
      h3 {
        color: #000;
        text-align: center;
        font-family: Oswald;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 52px;
        margin-top: 0;
        margin-bottom: 0;
      }
      h4 {
        color: #172e56;
        font-family: Roboto;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        max-width: 215px;
        width: 100%;
      }
      p {
        color: #000;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: 0.36px;
        max-width: 481px;
      }
      .post {
        flex-direction: row;
        padding-bottom: 50px;
        padding-top: 50px;
        margin: 0;
        @media (max-width: 1270px) {
          max-width: 800px;
          margin: 0 auto;
        }
        .post-image {
          box-sizing: border-box;
          margin-right: 3.5rem;
        }
        .gatsby-image-wrapper {
          margin-left: 0 !important;
          margin-right: 0;
          img {
            height: 175px;
            width: 292px;
            object-fit: cover;
            object-position: center;
          }
        }
        .text-wrapper {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 172px;
          p {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          a {
            color: #067eba;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.36px;
            margin-left: 0;
            text-transform: none;
            text-decoration: none;
            &.readmore-link:after {
              content: url(${arrow});
              position: relative;
              display: inline-block;
              height: 20px;
              width: 20px;
              transition: 0.2s all ease-in-out;
              left: 14px;
            }
            &:hover {
              text-decoration: none;
              &:after {
                left: 17px;
              }
            }
          }
        }
        border-bottom: 1px solid #d9d9d9;
        &:nth-of-type(3) {
          border-bottom: none;
        }
      }
      .button-wrapper {
        width: 100%;
        text-align: center;
        padding-top: 18px;
        .cta {
          border: 2px solid #41b6e6;
          color: #000;
          text-align: center;
          font-family: Oswald;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 111.111% */
          letter-spacing: 0.9px;
          text-transform: capitalize;
          padding: 15px 42px;
          outline: 1px solid rgb(245, 245, 245);
          &:hover {
            outline: 1px solid #41b6e6;
          }
        }
      }
    }
    @media only screen and (max-width: 1150px) {
      .content {
        .post {
          /* flex-direction: column;
          align-items: flex-start; */
          .text-wrapper {
            flex-direction: column;
            height: 100%;
            h4 {
              max-width: 360px;
            }
            p {
              text-align: left;
              margin-left: 0;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 900px) {
      padding-bottom: 35px;
      .content {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-top: 50px;
        .post {
          display: block;
          margin: 0 auto;
          max-width: 400px;
          .post-image {
            margin: 0 auto 22px auto;
            max-width: 100%;
            .gatsby-image-wrapper {
              max-height: 300px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .text-wrapper {
            box-sizing: border-box;
            h4 {
              max-width: 100%;
            }
            p {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  #panel5 {
    background-color: #172e56;
    .content {
      display: flex;
      flex-direction: row;
      max-width: 1440px;
      margin: 0 auto;
      .half {
        &:nth-of-type(1) {
          max-width: min(50%, 700px);
          width: 100%;
          margin-left: 0;
        }
        &:nth-of-type(2) {
          max-width: 487px;
          width: 100%;
          margin: 120px auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          -webkit-font-smoothing: antialiased;
          h2 {
            color: #fff;
            font-family: Oswald;
            font-size: 48px;
            font-style: normal;
            font-weight: 300;
            line-height: 52px;
            text-transform: uppercase;
            margin-top: 0;
          }
          p {
            color: #fff;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: 0.36px;
            margin: 0;
          }
          .cta {
            border-color: #41b6e6;
            display: block;
            margin-top: 27px;
            color: #fff;
            text-align: center;
            font-family: Oswald;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 111.111% */
            letter-spacing: 0.9px;
            text-transform: capitalize;
            padding: 15px 42px;
            align-self: flex-start;
            @media (max-width: 900px) {
              align-self: center;
            }
          }
        }
        .gatsby-image-wrapper {
          height: 100%;
          img {
            object-position: top right;
          }
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      .content {
        .half {
          &:nth-of-type(1) {
            margin-right: 50px;
          }
          &:nth-of-type(2) {
            padding: 20px;
            box-sizing: border-box;
          }
        }
      }
    }
    @media only screen and (max-width: 900px) {
      .content {
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;
        .half {
          margin: 0 auto !important;
          padding: 20px;
          &:nth-of-type(1),
          &:nth-of-type(2) {
            box-sizing: border-box;
            max-width: 480px;
          }
        }
      }
    }
  }
`;

const WithStaticData = React.memo((props) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "hex-bg.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1639, height: 629)
          }
        }
        panel2left: file(relativePath: { eq: "case-study-bkg.jpg" }) {
          publicURL
        }
        panel2right: file(relativePath: { eq: "panel-2-hexes.png" }) {
          publicURL
        }
        parallaxImage1: file(relativePath: { eq: "home/laclare.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920, 2400], layout: FULL_WIDTH)
          }
        }
        parallaxImage2: file(relativePath: { eq: "home/charter.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920, 2400], layout: FULL_WIDTH)
          }
        }
        parallaxImage3: file(relativePath: { eq: "home/berres.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920, 2400], layout: FULL_WIDTH)
          }
        }
        parallaxImage4: file(relativePath: { eq: "home/legit.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920, 2400], layout: FULL_WIDTH)
          }
        }
        parallaxImage5: file(relativePath: { eq: "home/shopko.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920, 2400], layout: FULL_WIDTH)
          }
        }
        parallaxImage6: file(relativePath: { eq: "home/jmi.jpg" }) {
          childImageSharp {
            gatsbyImageData(breakpoints: [500, 750, 1440, 1920, 2400], layout: FULL_WIDTH)
          }
        }
        monitorimg: file(relativePath: { eq: "white-monitor-outline.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 540, height: 449)
          }
        }
        screenimg: file(relativePath: { eq: "beefitarian-homepage.jpg" }) {
          publicURL
        }
        ogimg: file(relativePath: { eq: "og-img/home.jpg" }) {
          publicURL
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );
  const renderedChildren = props.children(data);
  return renderedChildren && React.Children.only(renderedChildren);
});
// WithStaticData.whyDidYouRender = { customName: 'WithStaticData' };

/*
        allWpPost(limit: 3, sort: { fields: [date], order: DESC }) {
          edges {
            node {
              id
              title
              link
              seo {
                metaDesc
              }
              featuredImage {
                node {
                  sourceUrl
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        width: 390
                        height: 370
                        transformOptions: { fit: COVER, cropFocus: CENTER }
                      )
                    }
                  }
                }
              }
            }
          }
        }
*/

const paralaxScrollStyleHook = () => {
  const defaultVal = { transform: 'translateY(0px)' };
  // const [paralaxStyle, setParalaxStyle] = useSpring(() => ({ transform: 'translateY(0px)' }));
  const [paralaxStyle, setParalaxStyle] = useState({ transform: 'translateY(0px)' });
  const [dispatch] = useScrollAnimation();

  const social1Move = (item) => {
    if (typeof item.scrollPct !== 'undefinded') {
      setParalaxStyle({
        transform: 'translateY(' + interpolateAndClamp([0, 1], [-244, -800], item.scrollPct) + 'px)',
      });
    }
  };
  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: 0,
      end: 1600,
      id: 'social-1',
      onUpdate: social1Move,
    });
  }, [dispatch]);
  return paralaxStyle;
  // return paralaxStyle && paralaxStyle.transform.payload[0].value ? paralaxStyle.transform.payload[0].value : 'translateY(0px)';
};

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const paralaxStyle = paralaxScrollStyleHook();
    // console.log(paralaxStyle);
    return <Component {...props} paralaxStyle={paralaxStyle} />;
  };
}
class AnimationFrame {
  constructor(fps = 60, animate) {
    this.requestID = 0;
    this.fps = fps;
    this.animate = animate;
  }

  start() {
    let then = performance.now();
    const interval = 1000 / this.fps;
    const tolerance = 0.1;

    const animateLoop = (now) => {
      this.requestID = requestAnimationFrame(animateLoop);
      const delta = now - then;

      if (delta >= interval - tolerance) {
        then = now - (delta % interval);
        this.animate(delta);
      }
    };
    this.requestID = requestAnimationFrame(animateLoop);
  }

  stop() {
    cancelAnimationFrame(this.requestID);
  }
}

// <AnimateBgImg src={data.panel2left.publicURL} />
const AnimateBgImg1 = (props) => {
  const [styles, api] = useSpring(() => ({
    scrollY: 0,
  }));
  const [vh, set_vh] = useState(1000);

  const updateScrollAnimation = () => {
    api.start({ to: { scrollY: window.scrollY } });
  };

  let setHeight = function () {
    set_vh(window.innerHeight);
  };

  useEffect(() => {
    setHeight();
    api.start();
    let timerID = new AnimationFrame(12, updateScrollAnimation);
    timerID.start();
    window.addEventListener('resize', setHeight);
    return () => {
      api.stop();
      timerID.stop();
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <a.img
      className="panel-background"
      alt="Beefitarian Campaign - Element Case Study"
      src={props.src}
      style={{
        position: 'absolute',
        width: '100%',
        transformOrigin: 'center',
        opacity: styles.scrollY.to({
          range: [vh * 1.1, vh * 1.7, vh * 2.3, vh * 2.5, vh * 3],
          output: [0.1, 0.6, 1, 1, 0.6],
          extrapolate: 'clamp',
        }),
        transform: styles.scrollY
          .to({
            range: [vh * 1.7, vh * 2.2],
            output: [0.7, 1],
            extrapolate: 'clamp',
          })
          .to((x) => `scale(${x})`),
      }}
    />
  );
};

const AnimateBgImg2 = (props) => {
  const [styles, api] = useSpring(() => ({
    scrollY: 0,
  }));
  const [vh, set_vh] = useState(1000);

  const updateScrollAnimation = () => {
    api.start({ to: { scrollY: window.scrollY } });
  };
  let setHeight = function () {
    set_vh(window.innerHeight);
  };

  useEffect(() => {
    setHeight();
    api.start();
    let timerID = new AnimationFrame(12, updateScrollAnimation);
    timerID.start();
    window.addEventListener('resize', setHeight);
    return () => {
      api.stop();
      timerID.stop();
      window.removeEventListener('resize', setHeight);
    };
  }, [api]);

  return (
    <a.img
      x-val={styles.scrollY}
      className=""
      alt="Beefitarian Campaign - Element Case Study"
      src={props.src}
      style={{
        position: 'absolute',
        width: '100%',
        left: '0',
        transform: styles.scrollY
          .to({
            range: [vh * 1.7, vh * 3],
            output: [300, 750],
            extrapolate: 'clamp',
          })
          .to((x) => `translateY(-${x}px)`),
        transformOrigin: 'center',
      }}
    />
  );
};

const CSSAnimH2 = ({ children }) => {
  const aosH2Ref = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const { isIntersecting } = useIntersectionObserver(aosH2Ref, { rootMargin: '0px 0px -160px 0px' });
  const isAnimReset = useIntersectionObserver(aosH2Ref, { rootMargin: '0px 0px 160px 0px' });

  const classNm = useMemo(() => {
    if (isIntersecting) {
      setIsAnimating(true);
    }

    if (!isAnimReset.isIntersecting && isAnimating) {
      setIsAnimating(false);
    }

    let classNm = 'fadeDownInit';
    if (isAnimating) {
      classNm += ' fadeInDown';
    }
    return classNm;
  }, [isIntersecting, isAnimating, isAnimReset.isIntersecting]);

  return (
    <h2 ref={aosH2Ref} className={classNm} style={{ color: '#000' }}>
      {children}
    </h2>
  );
};

/* This crazy logic is so we can "catch" the incoming scroll,
   match its speed and apply deceleration to smooth out the transition
   from scrolling to zooming.  Otherwise it feels like the text hit
   a wall and then "Pops" forward on a rapid zoom. */
const CreativityPanel = ({ vh }) => {
  const animRef = useRef();
  const lastPosRef = useRef(0);
  const lastScrollPosRef = useRef(0);
  const scrollPosAtRestRef = useRef(0);
  const transitionToScrollRef = useRef(false);
  let scroll = new SpringValue(vh * 1.1);

  const [vel, velApi] = useSpring(() => ({
    v: vh * 1.1,
  }));

  const [styles, api] = useSpring(() => ({
    scale: 1,
    opacity: 1,
    posY: 50,
    position: 'static',
    display: 'block',
    backgroundColor: '#000000',
    // config: { tension: 250, friction: 38, mass: 6 },
    // config: { tension: 440, friction: 50 },
    config: config.gentle,
  }));
  const isFixedRef = useRef(false);
  // const [vh, set_vh] = useState(1000);
  const [dispatch] = useScrollAnimation([vh]);

  const scrollUpdate = useCallback(
    (item) => {
      if (typeof item.scrollPos !== 'undefined' && item.scrollPos >= Math.floor(vh * 1.1)) {
        scroll.start(item.scrollPos);
        velApi.start({ v: item.scrollPos });
        // const zoomFactor = 1;
        const zoomFactor = interpolateAndClamp([Math.floor(vh * 2.1), vh * 2.45], [1, 2.2], item.scrollPos);
        const opacity = interpolateAndClamp([Math.floor(vh * 2.2), vh * 2.45], [1, 0], item.scrollPos);
        let posY = 0; //interpolateAndClamp([Math.floor(vh * 1.95), Math.floor(vh * 2.05)], [50, 0], item.scrollPos);

        let velocityConfig;
        // console.log({ posY });
        // console.log(styles.position);
        if (item.scrollPos >= Math.floor(vh * 1.88) && item.scrollPos < Math.floor(vh * 2.5)) {
          posY = interpolateAndClamp(
            [Math.floor(vh * 1.88), Math.floor(vh * 2.1)],
            [vh / 2 - vh * 0.04, vh / 2 - vh * 0.09],
            item.scrollPos
          );
          if (isFixedRef.current === false) {
            isFixedRef.current = true;

            let curPosInfo = animRef.current.getBoundingClientRect();
            // console.log({ curPosInfo: curPosInfo.y });

            velocityConfig = vel.v.velocity
              ? {
                  config: {
                    decay: 0.997,
                    velocity: -vel.v.velocity,
                  },
                  onChange: (animResult, springValue) => {
                    const pos = animResult.value.posY;
                    // console.log(pos.toFixed(2));
                    // Was vh / 2 - 20
                    if (pos < vh / 2 - vh * 0.06 || pos.toFixed(1) == lastPosRef.current) {
                      transitionToScrollRef.current = true;
                      scrollPosAtRestRef.current = item.scrollPos;
                      // console.log('stop');
                      api.stop(true);
                    }
                    lastPosRef.current = pos.toFixed(1);
                    //                    console.log({ animResult, springValue });
                  },
                  onRest: () => {
                    // console.log('onRest');
                    scrollPosAtRestRef.current = item.scrollPos;
                    transitionToScrollRef.current = true;
                  },
                }
              : null;
            // console.log('api.set(): posY=' + curPosInfo.y);
            api.set({ display: 'block', position: 'fixed', backgroundColor: '#000000', posY: curPosInfo.y }); //plus 120 margin
            api.start({ posY: vh / 2 - vh * 0.06, ...velocityConfig }); //Was vh / 2 + 15
          }
        }
        if (item.scrollPos < Math.floor(vh * 1.88) && isFixedRef.current === true) {
          isFixedRef.current = false;
          transitionToScrollRef.current = false;

          // let posY = 50;
          // if (lastPosRef.current > 0) {
          //   posY = 50 - (vh / 2 + 45 - parseFloat(lastPosRef.current));
          // }

          api.stop(true); //in case the decay is still running
          // console.log('stopped and cancelled!');
          api.set({ display: 'block', position: 'static', backgroundColor: '#000000', posY: 0 });
        }
        if (transitionToScrollRef.current === true) {
          // console.log('transition to scroll');
          const velocityEndPos = parseFloat(lastPosRef.current);

          // let curPosInfo = animRef.current.getBoundingClientRect();
          // console.log({ curPosInfo: curPosInfo.y, velocityEndPos, lastScrollPos: scrollPosAtRestRef.current });

          const direction = item.scrollPos > lastScrollPosRef.current ? 'down' : 'up';

          if (direction === 'down') {
            posY = interpolateAndClamp(
              [scrollPosAtRestRef.current, Math.floor(vh * 2.1)],
              [velocityEndPos, vh / 2 - vh * 0.09],
              item.scrollPos
            );
          } else {
            posY = interpolateAndClamp(
              [scrollPosAtRestRef.current, Math.floor(vh * 2.1)],
              [vh / 2 - vh * 0.04, vh / 2 - vh * 0.09],
              item.scrollPos
            );
          }

          // console.log({ scale: zoomFactor, posY: posY, opacity: opacity });
          api.start({
            display: 'block',
            scale: zoomFactor,
            posY: posY,
            opacity: opacity,
            config: { decay: null, velocity: null },
          });
        }
        // let curPosInfo = animRef.current.getBoundingClientRect();
        // console.log({ curPosInfo: curPosInfo.y });
        // console.log({ posY: posY, ...velocityConfig });
        // api.start({ posY: posY, ...velocityConfig });
        // api.start({ posY: posY });
      }

      //Sometimes observing the settings not getting reset on scrolling back to top
      if (typeof item.scrollPos !== 'undefined' && item.scrollPos < Math.floor(vh * 1.1)) {
        isFixedRef.current = false;
        transitionToScrollRef.current = false;
        api.set({ display: 'block', position: 'static', backgroundColor: '#000000', posY: 0, scale: 1, opacity: 1 });
      }
      if (
        typeof item.scrollPos !== 'undefined' &&
        item.scrollPos > Math.floor(vh * 2.5) &&
        item.scrollPos < Math.floor(vh * 2.9)
      ) {
        //If the page is refreshed when we are at the bottom, and we are scrolling up to the top, then we need a moment for the display to be block so the getBoundingClientRect() works
        api.set({ display: 'block' });
      }
      if (typeof item.scrollPos !== 'undefined' && item.scrollPos > Math.floor(vh * 2.9)) {
        api.set({ display: 'none', position: 'fixed', opacity: 0, scale: 2.2, posY: vh / 2 - vh * 0.09 });
      }
      lastScrollPosRef.current = item.scrollPos;
    },
    [vh, isFixedRef, scroll, vel, styles]
  );

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: vh / 4,
      end: vh * 5,
      id: 'creativity-panel',
      onUpdate: scrollUpdate,
    });
    return () => {
      dispatch({ type: 'removeItem', id: 'creativity-panel' });
    };
  }, [vh]);

  //  if ()

  return (
    <a.div
      className="panel-creativity"
      style={{
        pointerEvents: 'none',
        height: vh * 1.45 + 'px',
        position: 'relative',
        zIndex: 11,
        marginBottom: '-118vh',
        backgroundColor: 'black',
        opacity: styles.opacity,
        // transform: styles.scale.to((value) => {
        //   return `scale(${value}) translateZ(0)`;
        // }),

        // transform: styles.scrollY
        //   .to({
        //     range: [vh * 1.7, vh * 3],
        //     output: [0, 100],
        //     extrapolate: 'clamp',
        //   })
        //   .to((x) => `translateY(-${x}%)`),
        // transformOrigin: 'center',
      }}
    >
      <StyledPanel>
        <div
          style={{
            height: '60px',
            position: 'absolute',
            background: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 45px)`,
            left: '0',
            top: '-44px',
            width: '100%',
            zIndex: '10',
          }}
        ></div>
        <div className="content panel-creativity">
          <div className="copy-wrapper">
            <a.div
              ref={animRef}
              className="wrapper"
              style={{
                pointerEvents: 'none',
                display: styles.display,
                position: styles.position,
                // marginTop: '-210px', //Pull the text up so it enters earlier (as the hexes disappear)
                paddingLeft: '0px',
                top: '0px',
                // top: '57.5%',
                width: '840px',
                backgroundColor: styles.backgroundColor,
                left: 'calc(50% - 420px)',
                transformOrigin: 'top center',
                transform: to(
                  [styles.scale, styles.posY],
                  (scale, posY) => `translateY(${posY}px) scale(${scale}) translateZ(0)`
                ),
              }}
            >
              <h2 id="creativity">
                Creativity is the <span className="filled-text">spark</span> that ignites <AnimDesire vh={vh} />
              </h2>
            </a.div>
          </div>
        </div>
      </StyledPanel>
    </a.div>
  );
};

const RecentBlogs = ({ data, isMobile }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await fetch(`${NEXTJS_BASE_URL}/api/recent-posts/`);
        if (response.ok) {
          const data = await response.json();
          setBlogs(data.articles.data);
        }
      } catch (error) {
        console.error('Error fetching recent blog posts. Possible CORS issue?', error);
      }
    }
    //Fetch recent blog posts
    fetchPosts();
  }, []);

  if (!blogs || blogs.length == 0) {
    return <></>;
  }
  return (
    <>
      {blogs.map((node, idx) => {
        const seodescription = node.seofields?.seodescription;

        return (
          <div className="post" key={idx}>
            <div
              className="post-image"
              style={
                isMobile
                  ? { width: '100%' }
                  : {
                      width: '100%',
                      flex: '1 0 auto',
                      maxWidth: '292px',
                    }
              }
            >
              <a href={`/blog/${node.slug}/`} style={{ display: 'block' }}>
                <img
                  src={getStrapiMedia(node.image.formats.small.url)}
                  width={node.image.formats.small.width}
                  height={node.image.formats.small.height}
                  alt={node.image.alternativeText}
                  style={{
                    width: '100%',
                    height: isMobile ? '100%' : '175px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </a>
            </div>
            <div className="text-wrapper">
              <a href={`/blog/${node.slug}/`} style={{ display: 'block' }}>
                <h4 dangerouslySetInnerHTML={{ __html: node.title }} />
              </a>
              <p>
                <span dangerouslySetInnerHTML={{ __html: seodescription }} />
                &nbsp;
                <a href={`/blog/${node.slug}/`} className="readmore-link" style={{ display: 'block' }}>
                  Read&nbsp;Article
                </a>{' '}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

class Page extends React.Component {
  // static whyDidYouRender = { logOnDifferentValues: true };

  constructor(props) {
    super(props);
    this.state = {
      scrollIndicatorState: 0, //States: 0=haven't scrolled, too early for indicator, 1=haven't scrolled, ready for indicator, >1=hide indicator
      hideLightning: false,
      scrollMotion: 0,
      scrollTester: 0,
      fadeinSVG: false,
      showNav: false,
      // hexesCompleted: 0,
      // hexPulse: 0,
      showH1: false,
      showH2: false,
      introLoaded: false,
      vh: 880,
      vw: 1650,
      isMobile: false,
      showFirstAnimHexGroup: true,
      lockScrollAnim: false,
      noAnimateReveal: false,
    };

    this.pageProps = props;

    this.loadTime = 0;
    this.isMobile = false;

    this.autoScrollPos = {
      previous: 0,
      pos: 0,
    };

    // this.queuedPulses = [];
    this.pulsing = false;

    this.yPos = undefined;
    this.timerID = undefined;
    this.autoScrollAnim = undefined;

    this.videoRef = React.createRef();
    this.aosH2Ref = React.createRef();

    this.videoProgress = this.videoProgressFunc.bind(this);
    this.adjustHeight = this.adjustHeightFunc.bind(this);

    // this.screenRef = React.createRef();
    // this.screenSpring = null;
  }

  unlockScrolling() {
    window.document.body.style.height = 'auto';
    window.document.body.style.overflow = 'auto';
  }

  adjustHeightFunc() {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    this.setState({ vh: vh, vw: vw });
    this.setState((prev) => {
      if (vw <= 900 && prev.isMobile === false) {
        return { isMobile: true };
      } else if (vw > 900 && prev.isMobile === true) {
        return { isMobile: false };
      }
    });
    //Set an instance var so we can access it in componentDidMount()
    if (vw <= 900) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  videoProgressFunc() {
    if (this.videoRef && this.videoRef.current) {
      const percent = (this.videoRef.current.currentTime / this.videoRef.current.duration) * 100;
      // console.log(`video progress ${percent}%`);
      if (percent > 96) {
        this.videoRef.current.currentTime = 9.12;
      }

      if (window.performance.now() - this.loadTime > 60000) {
        this.videoRef.current.removeEventListener('timeupdate', this.videoProgress);
      }
    }
  }

  componentDidMount() {
    this.loadTime = window.performance.now();
    let supportsMaskImage = 'maskImage' in document.body.style || 'webkitMaskImage' in document.body.style;
    //Disable on Edge 17763 due to buggy behavior when two masks are animating atop each other.
    if (
      window.navigator &&
      (window.navigator.userAgent.match(/Edge\/18\.17763/) !== null ||
        window.navigator.userAgent.match(/Edge\/18\.18362/) !== null)
    ) {
      supportsMaskImage = false;
    }
    if (!supportsMaskImage) {
      document.body.classList.add('nomask');
    }

    const tick = () => {
      if (this.yPos !== window.scrollY || this.autoScrollPos.pos != this.autoScrollPos.previous) {
        this.scrollEvent();
        this.yPos = window.scrollY;
        this.autoScrollPos.previous = this.autoScrollPos.pos;
      }
    };
    this.timerID = new AnimationFrame(12, tick);
    this.timerID.start();

    this.adjustHeight();

    //This is a HACK.  It depends on an undocumented API (@@scroll session storage), and could therefore break.
    //Correct implementation requires probably wrapping the page component (see Gatsby API: wrapPageElement) with a Provider that tracks reach router location.key
    //See Also: https://github.com/reach/router/issues/119 and maybe https://stackoverflow.com/questions/54942047/how-to-get-previous-url-in-react-gatsby
    //May be helpful: https://github.com/reach/router/blob/master/src/index.js, https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby/cache-dir/navigation.js
    let isTop = true;
    // console.log('location key', this.pageProps.location.key);
    if (this.pageProps.location && this.pageProps.location.action && this.pageProps.location.action === 'POP') {
      isTop = false;
      // const asdf = sessionStorage.getItem('@@scroll|' + this.pageProps.location.key);
      // console.log('asdf', asdf);
      // if (asdf !== null) {
      //   const arr = JSON.parse(asdf);
      //   if (arr.length && arr[1] > 450) {
      //     isTop = false;
      //   }
      // }
    }
    // console.log({ isTop, mobile: this.isMobile });

    if (isTop && !this.isMobile) {
      // console.log('setting timers because ' + isTop);
      setTimeout(() => {
        // this.unlockScrolling();
        this.setState({ fadeinSVG: true });
        this.setState({ showNav: true });
      }, 2450);

      setTimeout(() => {
        this.setState({ showH1: true });
      }, 3400);

      setTimeout(() => {
        this.setState({ showH2: true });
        this.setState({ introLoaded: true });
      }, 3950);

      setTimeout(() => {
        this.setState((previous) => ({ scrollIndicatorState: previous.scrollIndicatorState + 1 }));
      }, 10500);
    } else {
      //If they are coming "Back" from a page, then we need a different set of defaults
      // console.log('skipping timers because ' + isTop);
      this.setState({
        fadeinSVG: true,
        showNav: true,
        showH1: false,
        showH2: false,
        introLoaded: true,
        noAnimateReveal: true,
        scrollIndicatorState: 2,
      });
    }

    // setTimeout(() => {
    //   //Auto-trigger the lightning and Service Hexes after 4.5 seconds if the user hasn't already scrolled.
    //   if (this.yPos === 0) {
    //     this.autoScrollAnim = anime({
    //       targets: this.autoScrollPos,
    //       pos: [{ value: [0, 450], delay: 0, duration: 1600 }],
    //       easing: 'linear',
    //       update: (anim) => {
    //         // window.scrollTo(0, this.autoScrollPos.pos);
    //       },
    //     });
    //   }
    // }, 4650);

    //lock the scrolling in the beginning during the video opening (only if scrollY is zero.  Otherwise we might be coming from a back-button mid-page)
    // if (window.scrollY === 0) {
    //   window.document.body.style.height = '100%';
    //   window.document.body.style.overflow = 'hidden';
    // }

    this.videoRef.current && this.videoRef.current.addEventListener('timeupdate', this.videoProgress);

    //TODO: Some sort of fallback option if Hls is NOT supported?
    if (Hls.isSupported()) {
      var video = this.videoRef.current;
      var hls = new Hls();
      // bind them together
      hls.attachMedia(video);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource('/videos/homevideo/video-v20191009-01.m3u8');
      });
    }

    window.addEventListener('resize', this.adjustHeight);
  }

  componentWillUnmount() {
    if (typeof this.timerID !== 'undefined') {
      this.timerID.stop();
    }

    if (typeof this.autoScrollAnim !== 'undefined') {
      this.autoScrollAnim.reset();
      this.autoScrollAnim = undefined;
    }

    this.unlockScrolling();
    this.videoRef.current && this.videoRef.current.removeEventListener('timeupdate', this.videoProgress);

    window.removeEventListener('resize', this.adjustHeight);
  }

  // panel3HexPositions(progress) {
  //   //return a COPY of the array (no mutation) so shallow comparison sees a change.
  //   const hexesCompleted = progress == 0 ? 0 : Math.floor((progress / 100) * 8);
  //   this.setState((prevState) => {
  //     if (hexesCompleted !== prevState.hexesCompleted) {
  //       this.queuePulse(hexesCompleted);
  //     }
  //     return {
  //       hexesCompleted: hexesCompleted,
  //     };
  //   });
  // }

  // queuePulse(size) {
  //   this.queuedPulses.push(size);
  // }

  // drainPulseQueue() {
  //   if (this.queuedPulses.length > 0 && !this.pulsing) {
  //     this.pulsing = true;
  //     let size = this.queuedPulses[0];
  //     this.setState({ hexPulse: 0.05 * size + 0.19 });
  //     setTimeout(() => {
  //       let size = this.queuedPulses.shift();
  //       this.setState({ hexPulse: 0.05 * size });
  //       this.pulsing = false;
  //     }, 100);
  //   }
  // }

  //called in AnimationFrame on a rhythm of 12fps
  scrollEvent = () => {
    const scrollY = window.scrollY;
    // this.setState((previous) => {
    //   // var obj = { scrollMotion: scrollY };
    //   var obj = {};
    //   if (scrollY > 650) obj.hideLightning = true;
    //   if (scrollY < 50) obj.hideLightning = false;
    //   //Hide (or prevent from ever showing) the scroll indicator on scroll.
    //   if (scrollY > 490) {
    //     obj.scrollIndicatorState = 2;
    //   }
    //   return obj;
    // });
    // if (this.screenSpring !== null && this.screenSpring.mounted) {
    //   this.screenSpring.controller.update({ to: { x: scrollY } });
    //   this.screenSpring.start();
    // }

    this.setState((previous) => {
      let obj = null;
      if (scrollY > 10 && previous.fadeinSVG === false) {
        obj = obj ?? {};
        obj.fadeinSVG = true;
      }

      if (scrollY > 650 && previous.hideLightning === false) {
        obj = obj ?? {};
        obj.hideLightning = true;
      }

      if (scrollY < 50 && previous.hideLightning === true) {
        obj = obj ?? {};
        obj.hideLightning = false;
      }

      if (scrollY > 490 && previous.scrollIndicatorState < 2) {
        obj = obj ?? {};
        obj.scrollIndicatorState = 2;
      }

      if (scrollY > 1100 && previous.showH1 === true) {
        obj = obj ?? {};
        obj.showH1 = false;
      } else if (scrollY <= 1100 && previous.introLoaded === true && previous.showH1 === false) {
        obj = obj ?? {};
        obj.showH1 = true;
      }

      if (scrollY > 480 && previous.showH2 === true) {
        obj = obj ?? {};
        obj.showH2 = false;
      } else if (scrollY <= 480 && previous.introLoaded === true && previous.showH2 === false) {
        obj = obj ?? {};
        obj.showH2 = true;
      }

      if (scrollY > previous.vh * 2.1 && previous.showFirstAnimHexGroup === true) {
        obj = obj ?? {};
        obj.showFirstAnimHexGroup = false;
      } else if (scrollY <= previous.vh * 2.1 && previous.showFirstAnimHexGroup === false) {
        obj = obj ?? {};
        obj.showFirstAnimHexGroup = true;
      }

      if (scrollY > previous.vh * 13.5 && previous.lockScrollAnim === false) {
        obj = obj ?? {};
        obj.lockScrollAnim = true;
      } else if (scrollY <= previous.vh * 13.5 && previous.lockScrollAnim === true) {
        obj = obj ?? {};
        obj.lockScrollAnim = false;
      }

      return obj;
    });

    // if (scrollY > 10 && !this.state.fadeinSVG) {
    //   this.setState({ fadeinSVG: true });
    // }

    // if (scrollY > 1100 && this.state.showH1) {
    //   this.setState({ showH1: false });
    // } else if (scrollY <= 1100 && this.state.introLoaded) {
    //   this.setState({ showH1: true });
    // }

    // if (scrollY > 480 && this.state.showH2) {
    //   this.setState({ showH2: false });
    // } else if (scrollY <= 480 && this.state.introLoaded) {
    //   this.setState({ showH2: true });
    // }

    // let panel3progress = interpolateAndClamp([Math.floor(this.state.vh * 3.9), this.state.vh * 4.6], [0, 100], scrollY);
    // this.panel3HexPositions(panel3progress);

    // this.drainPulseQueue();
  };

  render() {
    // const lightningPos = interpolateAndClamp([30, 400], [0, 11], this.state.scrollMotion);
    // const bgHexesPos = interpolateAndClamp([480, this.state.vh * 1.6], [0, 100], this.state.scrollMotion);
    // const paralaxStyle = this.props.paralaxStyle;

    return (
      <WithStaticData>
        {(data) => {
          //TODO: fetch this from a NextJS API in a useEffect
          data.allWpPost = { edges: [] };

          return (
            <>
              <Helmet>
                <title>Element | Wisconsin Advertising &amp; Content Marketing Agency</title>
                <meta
                  name="description"
                  content="We partner with clients to provide a blend of advertising, integrated marketing, and digital services with a down-to-earth culture and honest communication."
                />
                <meta property="og:title" content="Element | Wisconsin Advertising &amp; Content Marketing Agency" />
                <meta
                  property="og:description"
                  content="We partner with clients to provide a blend of advertising, integrated marketing, and digital services with a down-to-earth culture and honest communication."
                />
                <meta property="og:type" content="website" />
                <meta
                  name="ahrefs-site-verification"
                  content="649278e9e394b6b8762d55045e387421523d5ae2e28f105169343de5fcc174d1"
                />
                <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
                {data.ogimg && (
                  <meta property="og:image" content={data.site.siteMetadata.siteUrl + data.ogimg.publicURL} />
                )}
              </Helmet>
              {/* <ReactLenis root> */}
              <Layout showNav={this.state.showNav} hideFooterNewsletterSignup={true}>
                {this.state.isMobile ? (
                  <>
                    <HomeMobile vh={this.state.vh} />
                  </>
                ) : (
                  <div id="container">
                    {/*  fixed pos top container for service hexes */}
                    {/* Set a 100vh height so that it matches the height of panel1. Otherwise it would measure top
                50% off of 100% height, which can be slightly different from 100vh due to e.g. scrollbar. */}
                    <div
                      className={'dbl-page' + (this.state.fadeinSVG ? ' fadein-svg' : '')}
                      style={{
                        position: 'absolute',
                        zIndex: this.state.showFirstAnimHexGroup ? 10 : 2,
                        height: '100vh',
                        width: '100%',
                      }}
                    >
                      <div
                        className={
                          'hex-group-container hexcontainer' + (this.state.noAnimateReveal ? '' : ' animate-reveal')
                        }
                        style={
                          this.state.lockScrollAnim
                            ? {
                                position: 'absolute',
                                top: this.state.vh * 11.8 - 215,
                                maxWidth: '100%',
                                display: 'none',
                              }
                            : null
                        }
                      >
                        <AnimHexgroup vh={this.state.vh} vw={this.state.vw} />
                      </div>
                    </div>
                    {/* scroll container for intro section */}
                    <StyledPanel1 style={{ height: this.state.vh * 2.1 + 'px' }}>
                      {this.state.scrollIndicatorState < 2 && (
                        <MouseScrollIndicator
                          isHidden={this.state.scrollIndicatorState != 1 || this.state.scrollMotion > 455}
                        />
                      )}
                      <h1 className={this.state.showH1 ? 'reveal' : ''}>Element</h1>
                      <h2 className={this.state.showH2 ? 'reveal' : ''}>Fully Integrated Marketing</h2>
                      <div id="panel1" className={'dbl-page' + (this.state.fadeinSVG ? ' fadein-svg' : '')}>
                        <video ref={this.videoRef} className="homevideo" muted="muted" autoPlay="autoplay" />

                        {/* Perf improvement, when we're done with all these hexes, remove them from the DOM */}
                        {/* {bgHexesPos < 100 ? ( */}
                        <div
                          className={
                            'hex-group-container bghexes' + (this.state.noAnimateReveal ? '' : ' animate-reveal')
                          }
                        >
                          <Hexes
                          // animProgress={bgHexesPos} //animProgress controls e.g. movement of main hexes, opacity of other hexes, reveal/hide of service labels, enable/disable spinner animation
                          // lightningPos={lightningPos}
                          />
                        </div>
                        {/* ) : null} */}
                      </div>
                    </StyledPanel1>
                    {/*  scroll container for panel 2 */}

                    <div style={{ position: 'relative', top: '-25px' }}>
                      <div
                        style={{
                          height: this.state.vh * 1.25 + 'px',
                          position: 'absolute',
                          backgroundColor: 'black',
                          left: '0',
                          top: '0',
                          width: '100%',
                          height: '100%',
                          zIndex: '-1',
                        }}
                      ></div>
                    </div>
                    <CreativityPanel vh={this.state.vh} />
                    <div
                      style={{
                        // height: this.state.vh * 1.25 + 'px',
                        position: 'relative',
                        zIndex: 10,
                      }}
                    >
                      <StickySlider
                        image={getImage(data.parallaxImage1)}
                        alt="la clare"
                        description="LaClare Creamery"
                        stackIndex={10}
                        vh={this.state.vh}
                        enterVh={1.5}
                      >
                        <p>01 / 05</p>
                        <h3>LaClare Creamery</h3>
                        <p>
                          Life’s too short to eat anything less than extraordinary. See how we upped LaClare’s goat
                          cheese game and established their Unrivaled status.
                          <a href="/featured-project/laclare-creamery-food-brand-marketing-strategy/">
                            View Project{' '}
                            <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                                fill="#fff"
                              />
                            </svg>
                          </a>
                        </p>
                      </StickySlider>
                      <StickySlider
                        image={getImage(data.parallaxImage2)}
                        alt="Charter Steel"
                        stackIndex={11}
                        vh={this.state.vh}
                        enterVh={2.9} //prevSlide is 110vh with margin-bottom of 30vh, so 1.5 + 1.4 = 2.9
                      >
                        <p>02 / 05</p>
                        <h3>Charter Steel</h3>
                        <p>
                          Forged in fire, fueled by their people. Watch how we reignited Charter’s brand to reinforce
                          their reputation as an ally in all things steel.
                          <a href="/featured-project/charter-steel-b2b-marketing-brand-video-strategy/">
                            View Project{' '}
                            <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                                fill="#fff"
                              />
                            </svg>
                          </a>
                        </p>
                      </StickySlider>
                      <StickySlider
                        image={getImage(data.parallaxImage3)}
                        alt="Berres Brothers"
                        stackIndex={12}
                        vh={this.state.vh}
                        enterVh={4.3} //prevSlide is 110vh with margin-bottom of 30vh, so 2.9 + 1.4 = 4.3
                      >
                        <p>03 / 05</p>
                        <h3>Berres Brothers</h3>
                        <p>
                          Explore the wonders of the Berres Brothers Flavor Factory, a CGI world we crafted to brew up
                          interest in their fantastically flavored coffees.
                          <a href="/featured-project/berres-brothers-integrated-coffee-food-brand-marketing-strategy/">
                            View Project{' '}
                            <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                                fill="#fff"
                              />
                            </svg>
                          </a>
                        </p>
                      </StickySlider>
                      <StickySlider
                        image={getImage(data.parallaxImage4)}
                        alt="Legit Pizza"
                        stackIndex={13}
                        vh={this.state.vh}
                        enterVh={5.7} //prevSlide is 110vh with margin-bottom of 30vh, so 4.3 + 1.4 = 5.7
                      >
                        <p>04 / 05</p>
                        <h3>Legit Pizza</h3>
                        <p>
                          We topped ourselves with the Legit™ launch, delivering an authentic brand that cut through in
                          a saturated market. Your house or the best pizza joint around? It’s a toss-up.
                          <a href="/featured-project/legit-pizza-food-brand-marketing-strategy/">
                            View Project{' '}
                            <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                                fill="#fff"
                              />
                            </svg>
                          </a>
                        </p>
                      </StickySlider>
                      <StickySlider
                        image={getImage(data.parallaxImage5)}
                        alt="Shopko Optical"
                        stackIndex={14}
                        vh={this.state.vh}
                        enterVh={7.1} //prevSlide is 110vh with margin-bottom of 30vh, so 5.7 + 1.4 = 7.1
                        last={true}
                      >
                        <p>05 / 05</p>
                        <h3>Shopko Optical</h3>
                        <p>
                          Brand awareness and community engagement were the name of the game, so we drafted a strategy
                          that positioned Shopko Optical as a leading eye care provider.
                          <a href="/featured-project/shopko-optical-community-outreach/">
                            View Project{' '}
                            <svg width="21" height="16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
                                fill="#fff"
                              />
                            </svg>
                          </a>
                        </p>
                      </StickySlider>
                      <div
                        style={{
                          position: 'sticky',
                          zIndex: 15,
                          top: '100px',
                          height: '60vh',
                        }}
                      >
                        &nbsp;
                      </div>
                      {/* </LenisParallax> */}
                    </div>
                    <div id="panel3" className="full-page" style={{ zIndex: 1 }}>
                      <div className="hex-group-container">
                        <BigHex
                          vh={this.state.vh}
                          // hexPulse={this.state.hexPulse}
                          // hexesComplete={this.state.hexesCompleted}
                        />
                      </div>
                      <div className="content catalyst">
                        <h2>The Element Approach</h2>
                        <h3>
                          We have people and processes to optimize results and iterate faster than your competitors.
                        </h3>
                        <Link to="/integrated-marketing-services/" className="cta">
                          See the Difference
                        </Link>
                      </div>
                    </div>
                  </div>
                )}{' '}
                <RedesignPanel>
                  <div id="panel3-4" className="full-page" style={{ backgroundColor: '#fff' }}>
                    <div className="content">
                      <div className="half">
                        <div className="image-grid">
                          <StaticImage
                            src="../img/home/partners/shopko.svg"
                            alt="shopko optical logo"
                            className="partner-image"
                            style={{ maxWidth: '109px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/nicolet.svg"
                            alt="nicolet bank logo"
                            className="partner-image"
                            style={{ maxWidth: '121px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/kohler.svg"
                            alt="kohler logo"
                            className="partner-image"
                            style={{ maxWidth: '102px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/pg.svg"
                            alt="procter and gamble logo"
                            className="partner-image"
                            style={{ maxWidth: '95px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/just-bare.svg"
                            alt="just bare logo"
                            className="partner-image"
                            style={{ maxWidth: '110px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/we.svg"
                            alt="we energies logo"
                            className="partner-image"
                            style={{ maxWidth: '76px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/kaytee.svg"
                            alt="kaytee logo"
                            className="partner-image"
                            style={{ maxWidth: '123px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/pog.svg"
                            alt="pound of ground logo"
                            className="partner-image"
                            style={{ maxWidth: '87px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/peps.svg"
                            alt="pep's pizza company logo"
                            className="partner-image"
                            style={{ maxWidth: '144px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/usv.svg"
                            alt="us venture logo"
                            className="partner-image"
                            style={{ maxWidth: '125px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/grf.svg"
                            alt="grass run farms logo"
                            className="partner-image"
                            style={{ maxWidth: '133px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/aurora.svg"
                            alt="aurora baycare logo"
                            className="partner-image"
                            style={{ maxWidth: '143px' }}
                          />
                          {!this.state.isMobile && (
                            <StaticImage
                              src="../img/home/partners/laclare.svg"
                              alt="laclare creamery logo"
                              className="partner-image"
                              style={{ maxWidth: '94px' }}
                            />
                          )}
                          <StaticImage
                            src="../img/home/partners/tds.svg"
                            alt="tds logo"
                            className="partner-image"
                            style={{ maxWidth: '101px' }}
                          />
                          <StaticImage
                            src="../img/home/partners/reynolds.svg"
                            alt="reynolds logo"
                            className="partner-image"
                            style={{ maxWidth: '143px' }}
                          />
                        </div>
                      </div>
                      <div className="half">
                        <CSSAnimH2>
                          Real Partners.
                          <br />
                          Real Results.
                        </CSSAnimH2>
                        <p style={{ color: '#000' }}>
                          You'll notice that working with Element isn’t like working with other outside vendors—because
                          we're not a vendor. We're a partner and an extension of your team. By having real,
                          collaborative conversations about your business needs and goals, we can add value where it's
                          most needed and deliver marketing strategies that will drive results. The bottom line is we
                          exist to boost <strong>your</strong> bottom line.
                        </p>
                        <Link to="/contact-wisconsin-agency/" className="cta">
                          Partner with Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </RedesignPanel>
                {/* isMobile */}
                <RedesignPanel>
                  <div id="panel4" className="full-page" style={{ backgroundColor: '#f5f5f5', paddingTop: '68px' }}>
                    <div className="content">
                      <h3>AGENCY INSIGHTS</h3>
                      <RecentBlogs data={data.allWpPost} isMobile={this.state.isMobile} />
                      <div className="button-wrapper">
                        <Link to="/blog/" className="cta">
                          See All Articles
                        </Link>
                      </div>
                    </div>
                  </div>
                </RedesignPanel>
                <FooterForm reversed={true} />
                <RedesignPanel>
                  <div id="panel5">
                    <div className="content">
                      <div className="half">
                        <StaticImage src="../img/home/group.jpg" alt="Element Culture" />
                      </div>
                      <div className="half">
                        <h2>Attitude is Everything</h2>
                        {/* NOTE: The \u2014 is because I can't figure out how to get Gatsby to accept a &emdash; ?? */}
                        <p>
                          We work with purpose and put passion into all we do. Our combination of down-to-earth culture
                          and direct communication inspires authentic connections, leading to lasting partnerships built
                          on trust. We're not just a team. We're a creative collective of empowered marketing experts
                          working collaboratively and thoughtfully with agility and confidence. We work hard and find
                          satisfaction in getting it done {'\u2014'} together {'\u2014'} blending a results-driven work
                          ethic with joy and inspiration.
                        </p>
                        <Link to="/agency-careers/" className="cta">
                          Join Our Team
                        </Link>
                      </div>
                    </div>
                  </div>
                </RedesignPanel>
              </Layout>
              {/* </ReactLenis> */}
            </>
          );
        }}
      </WithStaticData>
    );
  }
}

const LocationContext = React.createContext('Location');
Page.contextType = LocationContext;
export default Page;
// export default withMyHook(Page);
